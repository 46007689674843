import React from 'react'

export const PageTitle = ({ title, subtitle }) => <div className="text-center">
  <h1 className="text-5xl tracking-tight text-blue-700">
    {title}
  </h1>
  <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
    {subtitle}
  </p>
</div>


export const PageSubTitle = ({ title, subtitle }) => <div className="text-center">
  <h2 className="text-4xl tracking-tight text-blue-700">
    {title}
  </h2>
  <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
    {subtitle}
  </p>
</div>
